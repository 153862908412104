import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import FundWalletDialog from "../../../modals/FundWalletDialog";
import { useEffect, useState } from "react";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import axios from "axios";
import { getDHLPickupLocationsURL, getShipmentPricingURL } from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";
import Interstate3PLPriceSettingsDialog from "../../../modals/Interstate3PLPriceSettingsDialog";
import International3PLPriceSettingsDialog from "../../../modals/International3PLPriceSettingsDialog";
import PriceIcon from "../../../assets/settings/price_settings/credit_card_gear.svg";


const PriceSettings = () => {
    const [intracityMotorbikePriceSettingsDialogVisibility, setIntracityMotorbikePriceSettingsDialogVisibility] = useState(false);
    const [interstate3PLPriceSettingsDialogVisibility, setInterstate3PLPriceSettingsDialogVisibility] = useState(false);
    const [international3PLPriceSettingsDialogVisibility, setInternational3PLPriceSettingsDialogVisibility] = useState(false);

    const [shipmentPricing, setShipmentPricing] = useState([]);

    const handleCloseModal = () => {
        setIntracityMotorbikePriceSettingsDialogVisibility(false);
        setInterstate3PLPriceSettingsDialogVisibility(false);
        setInternational3PLPriceSettingsDialogVisibility(false);
    };

    const showMotorbikeDialog = () => {
        setIntracityMotorbikePriceSettingsDialogVisibility(!intracityMotorbikePriceSettingsDialogVisibility);
    }

    const showInterstate3PLDialog = () => {
        setInterstate3PLPriceSettingsDialogVisibility(!interstate3PLPriceSettingsDialogVisibility);
    }

    const showInternational3PLDialog = () => {
        setInternational3PLPriceSettingsDialogVisibility(!international3PLPriceSettingsDialogVisibility);
    }

    useEffect(() => {
        axios
            .post(getShipmentPricingURL, UserProfileData())
            .then(function (response) {
                if (response["data"]["success"] === true) {
                    console.log(response);
                    setShipmentPricing(response["data"]);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [])

    return (
        <>
            <PriceSettingsDialog
                isModalVisible={intracityMotorbikePriceSettingsDialogVisibility}
                handleCloseDialog={handleCloseModal}
                shipmentPricing={shipmentPricing}
            />

            <Interstate3PLPriceSettingsDialog
                isModalVisible={interstate3PLPriceSettingsDialogVisibility}
                handleCloseDialog={handleCloseModal}
                shipmentPricing={shipmentPricing}
            />

            <International3PLPriceSettingsDialog
                isModalVisible={international3PLPriceSettingsDialogVisibility}
                handleCloseDialog={handleCloseModal}
                shipmentPricing={shipmentPricing}
            />
            <div className="settings_section d-flex flex-xl-row flex-column align-items-start" >
                <div className="d-flex align-items-center justify-content-between price-settings-left" >
                    <div className="d-flex gap-3 align-items-start" >
                        <img src={PriceIcon} alt="price" className="mt-2" />
                        <div>
                            <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>Price Settings</p>
                            <span>Simple pricing. No hidden fees. Advanced features for your business.</span>
                        </div>
                    </div>
                </div>
                <div className=" price-settings-right">
                    <div className=" col-12">
                        <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4" >
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 18, color: "#2C3E50" }}>Intracity Motorbike Pricing</span>
                                <span style={{ fontSize: 14 }}>Pricing for shipments done within a city by bikes</span>
                            </div>

                            <div onClick={(e) => showMotorbikeDialog()} className="d-flex justify-content-center align-items-center btn-primary oval-button">
                                <span>Update</span>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4" >
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 18, color: "#2C3E50" }}>Interstate 3PL Pricing</span>
                                <span style={{ fontSize: 14 }}>Set your commission for 3PL interstate shipments</span>
                            </div>

                            <div onClick={(e) => showInterstate3PLDialog()} className="d-flex justify-content-center align-items-center btn-primary oval-button">
                                <span>Update</span>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 18, color: "#2C3E50" }}>International 3PL Pricing</span>
                                <span style={{ fontSize: 14 }}>Set your commission for 3PL international shipments</span>
                            </div>

                            <div onClick={(e) => showInternational3PLDialog()} className="d-flex justify-content-center align-items-center btn-primary oval-button">
                                <span>Update</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PriceSettings;