import { Button, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL,
    getContractDetailsUserURL, getInterstate3PLPricingURL, getShipmentPricingURL, getShipmentsURL,
    makeContractUserURL, topupUserWalletURL, updateInterstate3PLPricingURL
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BiX } from "react-icons/bi";
import * as React from "react";
import { SessionManagement } from "../library/SessionManagement";
import { TextBox } from "../components/InputFields";

const SubmitButton = styled.button`
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const Interstate3PLPriceSettingsDialog = ({ isModalVisible, handleCloseDialog, shipmentPricing }) => {
    const form = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [dhlPricing, setDHLPricing] = useState("");
    const [redstarPricing, setRedstarPricing] = useState("");
    const [othersPricing, setOthersPricing] = useState("");

    useEffect(() => {
        axios
            .post(getInterstate3PLPricingURL, UserProfileData())
            .then(function (response) {
                console.log(response);
                if (response["data"]["success"] === true) {
                    if (response["data"]["data"] === false) {

                    } else {
                        const dhl = response["data"]["data"]["data"]["dhl"];
                        const redstar = response["data"]["data"]["data"]["redstar"];

                        setDHLPricing(dhl)
                        setRedstarPricing(redstar)
                    }
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const onChangeCarrier = (type, event) => {
        if (type === "dhl") {
            setDHLPricing(event.target.value);
        } else if (type === "redstar") {
            setRedstarPricing(event.target.value);
        }
    }

    const setInterstate3PLPricing = () => {
        const formValues = {};

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        console.log(data)

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        console.log(formValues);

        axios
            .post(updateInterstate3PLPricingURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage(response["data"]["data"]["data"]);

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                return error;
            });
    }

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 style={{ marginTop: 10 }} className="header">SET INTERSTATE 3PL PRICING</h5>
                <BiX style={{ fontSize: 25, cursor: "pointer" }} onClick={handleCloseDialog} />
            </Modal.Header>
            <Modal.Body>
                {
                    isError === false ? null :
                        (<ErrorMessageContainer>
                            {errorMessage}
                        </ErrorMessageContainer>)
                }

                {
                    isSuccess === false ? null :
                        (<SuccessMessageContainer>
                            {successMessage}
                        </SuccessMessageContainer>)
                }

                <form ref={form}>
                    <div>
                        <div>
                            <Row className="mt-3">
                                <Col>
                                    <label htmlFor="Base From">DHL (%)</label>
                                    <TextBox
                                        value={dhlPricing} 
                                        onChange={(e) => onChangeCarrier("dhl", e)} 
                                        name="dhl" 
                                        type="number"
                                        placeholder="Enter Price in Percentile"
                                    />
                                </Col>
                                <Col>
                                    <label htmlFor="Base To">Redstar (%)</label>
                                    <TextBox 
                                        value={redstarPricing} 
                                        onChange={(e) => onChangeCarrier("redstar", e)} 
                                        name="redstar" 
                                        type="number" 
                                        placeholder="Enter Price in Percentile"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </form>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
                    Cancel
                </button>
                <SubmitButton onClick={(e) => setInterstate3PLPricing()} type="button" className="btn-primary">
                    {isSubmitting === false ? (
                        <span className="button-text">Confirm</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>

    );
};

export default Interstate3PLPriceSettingsDialog;
