import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { UserProfileData } from "../../library/constants";
import { getShipmentsURL } from "../../library/URLs";

import * as React from "react";
import styled from "styled-components";
import Activities from "../../assets/dashboard/network_intelligence_history_small.svg";
import { BsChevronDoubleRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecentShipments = () => {
  const [isRetrieving, setIsRetrieving] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [recentShipments, setRecentShipments] = useState([]);
  const navigate = useNavigate();

  const data = {
    limit: 6,
    offset: 0,
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getShipmentsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsRetrieving(false);

        if (response["data"]["success"] === false) return;

        const recentShipments = response["data"]["data"];

        if (recentShipments.length === 0)
          setIsEmpty(true);

        setRecentShipments(recentShipments);
      });
  }, []);

  return (
    <div className="col-xl-4 col-md-12 mb-4 p-0 grey-cards mb-xl-0">
      <div className="card border-0" style={{backgroundColor: "transparent"}}>
        <div className="card-body recent-shipments-container">
          <div className="d-flex justify-content-between mb-3" >
            <div className="recent_title" >
              <img src={Activities} alt="shipments" />
              <p className="" >Recent Shipments</p>
            </div>
            <div className="recent_title" style={{cursor:"pointer"}} onClick={()=>navigate("/shipments/all-shipments")} >
              <p className="" >View All</p>
              <BsChevronDoubleRight style={{color: "#D0D5DD",fontSize: 10}} />
            </div>
          </div>
          {
            isRetrieving === true ?
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                </LoadingContainer> : isEmpty === true ?
                <EmptyContainer>
                  <p>Oops... Sorry, you have no recent shipments</p>
                </EmptyContainer> :
                <div className="recent-shipment-body">
                  {recentShipments.map((shipment) => {
                    const statusClass = shipment.status.toString().replaceAll(" ", "");
                    return (
                        <a key={shipment.id} href={"/shipment/" + shipment.id}>
                          <div className="d-flex recent-shipment-row">
                            {/* <img
                                src={shipment.profile_image_url}
                                className="avatar"
                                alt=""
                            /> */}
                            <div className="content">
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="name">{shipment.sender_name}</span>
                                <div className={`status ${statusClass}`}>{shipment.status}</div>
                              </div>
                              <div>
                    <span className="destination">
                      {
                        shipment.drop_off_location?.length > 22 ?
                        `${shipment.drop_off_location?.slice(0, 22)}...` :
                        shipment.drop_off_location
                      }
                    </span>
                              </div>
                            </div>
                            <hr />
                          </div>
                        </a>
                    );
                  })}
                </div>
          }
        </div>
      </div>
    </div>
  );
};

export default RecentShipments;
