import "../styles/home.scss";
import "../styles/dashboard.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import DashboardGraph from "./Widgets/DashboardGraph";
import OverallStatistics from "./Widgets/OverallStatistics";
import TransporterActivities from "./Widgets/TransporterActivities";
import RecentShipments from "./Widgets/RecentShipments";
import RecentTransactions from "./Widgets/RecentTransactions";
import { SessionManagement } from "../library/SessionManagement";
import UserActivities from "./Widgets/UserActivities";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  getDashboardStatisticsURL,
  getDashboardShipmentStatsUrl,
} from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { URLConnection } from "../library/URLConnection";
import SubscriptionInactive from "../components/SubscriptionInactive";
import Overview from "../assets/dashboard/overview.svg";
import Graph from "../assets/dashboard/bar_chart.svg";
import Activities from "../assets/dashboard/network_intelligence_history.svg";
import { BsChevronDoubleDown } from "react-icons/bs";
import { useRef } from "react";

const Dashboard = () => {
  const [totalRevenue, setTotalRevenue] = useState("...");
  const [totalShipments, setTotalShipments] = useState("...");
  const [totalCustomers, setTotalCustomers] = useState("...");
  const [totalTransactions, setTotalTransactions] = useState("...");
  const [mode, setMode] = useState("daily");

  const ref = useRef();

  const [shipmentLabels, setShipmentLabels] = useState([]);
  const [shipmentValues, setShipmentValues] = useState([]);

  const getShipmentStats = () => {
    URLConnection(
      getDashboardShipmentStatsUrl,
      { ...UserProfileData(), mode: mode },
      function (response) {
        if (response["data"]["success"] === false) return;

        let shipmentsValues = [];
        let shipmentLabels = [];

        let shipments = response["data"]["data"];

        shipments?.forEach((shipment) => {
          shipmentsValues.push(shipment.count);
          shipmentLabels.push(shipment.date);
        });

        setShipmentLabels(shipmentLabels);
        setShipmentValues(shipmentsValues);
      }
    );
  };

  useEffect(() => {
    getShipmentStats();
    // eslint-disable-next-line
  }, [mode])

  useEffect(() => {
    URLConnection(
      getDashboardStatisticsURL,
      UserProfileData(),
      function (response) {
        if (response["data"]["success"] === false) return;

        const totalRevenue = response["data"]["overall"]["total_revenue"];
        const totalShipments = response["data"]["overall"]["shipments"];
        const totalCustomers = response["data"]["overall"]["customers"];
        const totalTransactions = response["data"]["overall"]["transactions"];

        setTotalRevenue(parseFloat(totalRevenue).toLocaleString("en"));
        setTotalShipments(parseFloat(totalShipments).toLocaleString("en"));
        setTotalCustomers(parseFloat(totalCustomers).toLocaleString("en"));
        setTotalTransactions(
          parseFloat(totalTransactions).toLocaleString("en")
        );
      }
    );
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="d-flex align-items-center flex-row gap-2 mb-2 mx-3">
            <img src={Overview} alt="overview" />
            <h5 className="m-0">Overview</h5>
          </div>
          <div className="container ">
            <OverallStatistics
              totalCustomers={totalCustomers}
              totalRevenue={totalRevenue}
              totalShipments={totalShipments}
              totalTransactions={totalTransactions}
            />

            <div className=" d-flex mt-4 gap-2">
              <div
                className=" card w-100 border-0 pt-2 mb-4 mb-md-0"
                style={{ backgroundColor: "#FFF", borderRadius: "16px" }}
              >
                <div
                  className=" border-0"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="d-flex justify-content-between align-items-center" >
                      <div className="d-flex align-items-center flex-row gap-2 mb-2 mx-3">
                        <img src={Graph} alt="graph" />
                        <h5 className="m-0">Shipments Analytics</h5>
                      </div>

                    <div className="graph-select">
                      <select
                        name="mode"
                        value={mode}
                        ref={ref}
                        onChange={(e) => setMode(e.target.value)}
                        id=""
                      >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                      </select>

                      <BsChevronDoubleDown onClick={()=>ref.current.focus()} />
                    </div>
                  </div>
                  <div className="p-3">
                    <div className="graph-bg rounded-4">
                      <DashboardGraph
                        label={shipmentLabels}
                        shipmentsData={shipmentValues}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <RecentTransactions />
            </div>

            <div
              className="row mt-4 mx-1 activities-contain"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex align-items-center flex-row gap-2 mb-2 mx-3">
                <img src={Activities} alt="graph" />
                <h5 className="m-0">Activities</h5>
              </div>
              <div className="row d-flex gap-2 flex-nowrap">
                <RecentShipments />

                <TransporterActivities />

                <UserActivities />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
