import "../styles/home.scss";
import "../styles/customers.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import {
  getStationsURL,
  searchForCustomersURL,
} from "../library/URLs";
import styled from "styled-components";
import * as React from "react";
import { BsDot } from "react-icons/bs";
import { map } from "react-bootstrap/ElementChildren";
import SubscriptionInactive from "../components/SubscriptionInactive";
import StationsIcon from "../assets/sidebar/Stations.svg";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Stations = () => {
  const form = useRef(null);
  const navigator = useNavigate();

  const [isRetrievingMore, setIsRetrievingMore] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [stations, setStations] = useState([]);

  const [isQuerySearching, setIsQuerySearching] = useState(false);
  const [resultContainerVisibility, setResultContainerVisibility] =
    useState(false);
  const [customerQueryResults, setCustomerQueryResults] = useState([]);

  const data = {
    limit: 20,
    offset: currentOffset,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getStationsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (isLoading === false && isRetrievingMore === false) {
          console.log("Should not retrieve");
          return;
        }
        console.log(response);

        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newCustomers = response["data"]["data"]["data"];

        if (newCustomers.length > 0) {
          const index = newCustomers[newCustomers.length - 1].id;

          setCurrentOffset(index);
        }

        if (currentOffset === 0) {
          setStations(newCustomers);
        } else {
          setStations([...stations, ...newCustomers]);
        }
      });
  }, [isRetrievingMore]);

  const handleClick = (stationID) => {
    navigator("/stations/" + stationID);
  };

  const searchForCustomers = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = () => {
    const formData = new FormData(form.current);
    const query = formData.get("query");

    if (query.toString().trim() === "") {
      setIsQuerySearching(false);
      setResultContainerVisibility(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerQueryResults(customers);
    });
  };

  const setScroll = () => {
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false){
    //     setIsRetrievingMore(true);
    // }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={StationsIcon} alt="stations" />
                  <h5 className="m-0">Stations</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <button
                    className="confirm-button btn-primary"
                    type="button"
                    onClick={() => navigator("/stations/create")}
                  >
                    Create Station
                  </button>
                </div>
              </div>
              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Address</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">State</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {stations.map((customer) => {
                        return (
                          <tr
                            key={customer.id}
                            onClick={() => handleClick(customer.id)}
                          >
                            <td>{customer.address}</td>
                            <td>{customer.fullname}</td>
                            <td>{customer.phone}</td>
                            <td>{customer.state}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>

              {isRetrievingMore === false ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stations;
