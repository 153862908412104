import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import { getTransactionsURL } from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import TransactionIcon from "../assets/sidebar/Transactions.svg";
import Pagination from "../components/Pagination";
import ExportIcon from "../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Shipments = () => {
  const navigator = useNavigate();
  const params = useParams();

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffset, setCurrentOffset] = useState(0);

  const [totalPages, setTotalPages] = useState();
  const [openExport, setOpenExport] = useState(null);
  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);

  const [searchParam] = useSearchParams();

  const page = searchParam.get("page");

  useEffect(() => {
    if (page) {
      getTransactions();
    }
    // eslint-disable-next-line
  }, [page]);

  const [isOptionSelected, setIsOptionSelected] = useState("");

  const [transactions, setTransactions] = useState([]);

  const getTransactions = async () => {
    const data = {
      limit: 20,
      payment_status: chosenPaymentStatus,
      date: chosenDate,
      paginated: "Yes",
      page: page ?? "1",
    };

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(getTransactionsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const transactions = response["data"]["data"]["transactions"];
        const total_pages = response["data"]["data"]["total_pages"];

        setTransactions(transactions);
        setTotalPages(total_pages);
      });
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line
  }, [isOptionSelected]);

  const handleClick = (transactionID) => {
    navigator("/transaction/" + transactionID);
  };

  const exportToCSV = () => {
    if (transactions.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Sender Name,Type,Channel,Payment Type,Amount,Status,DateTime" + 
      "\r\n";

    transactions.forEach(function (transaction) {

      const row =
        transaction.sender_name +
        "," +
        transaction.type +
        "," +
        transaction.channel +
        "," +
        transaction.payment_type +
        "," +
        transaction.amount +
        "," +
        transaction.status +
        "," +
        transaction.date_time;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "Transactions.csv");
    document.body.appendChild(link);

    link.click();
  };

  const onChangePaymentStatus = (event) => {
    setChosenPaymentStatus(event.target.value);
  };

  const toggleCustomDateRangeDialog = () => {
    setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
  };

  const onChangeShipmentDate = (event) => {
    if (event.target.value === "custom") {
      toggleCustomDateRangeDialog();
    } else {
      setChosenDate(event.target.value);
    }
  };

  const getReports = () => {
    if (chosenPaymentStatus !== "" && chosenDate !== "") {
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(chosenPaymentStatus + chosenDate);
    }
  };

  const paginate = (num) => {
    setCurrentPage(num);
    navigator(`/transactions?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    navigator(`/transactions?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    navigator(`/transactions?page=${currentPage - 1}`);
  };

  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <CustomDateRangeDialog
              isModalVisible={isCustomDateRangeDialogVisible}
              handleCloseDialog={(e) => {
                if (typeof e === "string") {
                  console.log("The chosen date is " + e.toString());
                  setChosenDate(e);
                }
                toggleCustomDateRangeDialog("close");
              }}
            />

            <div
              className="card border-0 p-4 min-vh-100"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={TransactionIcon} alt="group" />
                  <h5 className="m-0">Transactions</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <div className="d-grid d-md-flex gap-4">
                    <div className="d-flex gap-2">
                      <select
                        onChange={onChangePaymentStatus}
                        name="pickup_detail_id"
                        style={{
                          fontSize: 12,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        className="form-select shipment-top-input"
                      >
                        <option value="">Filter By</option>
                        <option value="Credit">Credit Payments</option>
                        <option value="Debit">Debit Payments</option>
                      </select>

                      <select
                        onChange={onChangeShipmentDate}
                        name="pickup_detail_id"
                        style={{
                          fontSize: 12,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        className="form-select shipment-top-input"
                      >
                        <option value="">Choose date</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this week">This Week</option>
                        <option value="this month">This Month</option>
                        <option value="custom">Custom Date</option>
                      </select>

                      <button
                        onClick={getReports}
                        className="btn-primary"
                        style={{ padding: "2px 16px" }}
                      >
                        <BiIcons.BiSearch style={{ fontSize: 14 }} />
                      </button>
                    </div>

                    <div>
                      <button
                        className="feature-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickExport}
                      >
                        <img src={ExportIcon} alt="export" />
                        Export
                        {open ? <BsChevronUp /> : <BsChevronDown />}
                      </button>
                      <Menu
                        id="basic-menu"
                        anchorEl={openExport}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            exportToCSV();
                            handleClose();
                          }}
                          style={{ fontSize: 12 }}
                        >
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Type</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {transactions.map((transaction) => {
                        const dateTime = moment(transaction.date_time);
                        const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                        const color =
                          transaction.payment_type === "Credit"
                            ? "green"
                            : "red";
                        return (
                          <tr
                            key={transaction.id}
                            onClick={() => handleClick(transaction.id)}
                          >
                            <td>{transaction.sender_name}</td>
                            <td>{transaction.type}</td>
                            <td>
                              ₦
                              {parseFloat(transaction.amount).toLocaleString(
                                "en"
                              )}
                            </td>
                            <td>
                              <span className="d-flex align-items-center gap-1">
                                <GoPrimitiveDot style={{ color: color }} />
                                {transaction.payment_type}
                              </span>
                            </td>
                            <td>{timestamp}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>

              {!isLoading && (
                <Pagination
                  total_pages={totalPages}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  current_page={currentPage}
                />
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipments;
