import * as React from "react";
import { useEffect, useState } from "react";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
    getCustomerWalletDetailsURL,
    getCustomerWalletHistoryURL,
} from "../../../library/URLs";
import styled from "styled-components";
import { GoPrimitiveDot } from "react-icons/go";
import TopupUserWalletDialog from "../../../modals/TopupUserWallet";
import DeductUserWalletDialog from "../../../modals/DeductUserWalletDialog";
import moment from "moment";
import Balance from "../../../assets/single-customer/deployed_code_active.svg";
import Funded from "../../../assets/single-customer/payments.svg";
import Spent from "../../../assets/single-customer/Currency.svg";
import WalletIcon from "../../../assets/single-customer/wallet_icon.svg";
import TransactionIcon from "../../../assets/single-customer/account_balance_wallet.svg";
import FundIcon from "../../../assets/single-customer/fund_wallet.svg";
import DeductIcon from "../../../assets/single-customer/deduct_wallet.svg";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerWallet = ({ customerID, customerPhoneNumber, customerName }) => {
    const [isFundUserWalletModalVisible, setIsFundUserWalletModalVisibility] =
        useState(false);
    const [isDeductUserWalletModalVisible, setIsDeductUserWalletModalVisibility] =
        useState(false);

    const [accountBalance, setAccountBalance] = useState("");
    const [totalFunded, setTotalFunded] = useState("");
    const [totalSpent, setTotalSpent] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [walletHistory, setShipments] = useState([]);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);
    const [currentOffset, setCurrentOffset] = useState(0);

    const data = {
        customer_id: customerID,
        limit: 50,
        offset: currentOffset,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getCustomerWalletDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;
                console.log(response["data"]);
                const accountBalance = response["data"]["data"]["account_balance"];
                const totalFunded = response["data"]["data"]["total_funded"];
                const totalSpent = response["data"]["data"]["total_spent"];

                setAccountBalance(accountBalance);
                setTotalFunded(totalFunded);
                setTotalSpent(totalSpent);
            });
    }, []);

    useEffect(() => {
        axios
            .post(getCustomerWalletHistoryURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsLoading(false);
                setIsRetrievingMore(false);

                if (response["data"]["success"] === false) return;

                const shipments = response["data"]["data"];

                if (shipments.length > 0) {
                    const index = shipments[shipments.length - 1].id;

                    setCurrentOffset(index);
                }

                if (shipments.length === 0) setIsEmpty(true);

                // console.log("pff", shipments);

                // console.log("apos",[...walletHistory, ...shipments]);
                setShipments(shipments);

                // if (currentOffset === 0){
                //     setShipments(walletHistory);
                // } else {
                //     setShipments([...walletHistory, ...shipments]);
                // }
            });
    }, []);

    const editCustomerProfile = () => { };

    const handleClick = () => { };

    const openTopupUserWalletDialog = () => {
        setIsFundUserWalletModalVisibility(true);
    };

    const closeTopupUserWalletDialog = (refresh) => {
        setIsFundUserWalletModalVisibility(false);
        if (refresh === "Refresh") {
            window.location.reload();
        }
    };

    const openDeductUserWalletDialog = () => {
        setIsDeductUserWalletModalVisibility(true);
    };

    const closeDeductUserWalletDialog = (refresh) => {
        setIsDeductUserWalletModalVisibility(false);
        if (refresh === "Refresh") {
            window.location.reload();
        }
    };

    // const setScroll = () => {
    //     if(currentOffset !== 0) {
    //         if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight-200) && isRetrievingMore === false){
    //             setIsRetrievingMore(true);
    //         }
    //     }
    // };

    // React.useEffect(() => {
    //     window.addEventListener("scroll", setScroll);
    //     return () => {
    //         window.removeEventListener("scroll", setScroll);
    //     };
    // }, []);

    return (
        <>
            <TopupUserWalletDialog
                customerID={customerID}
                phoneNumber={customerPhoneNumber}
                customerName={customerName}
                isModalVisible={isFundUserWalletModalVisible}
                handleCloseDialog={closeTopupUserWalletDialog}
            />

            <DeductUserWalletDialog
                customerID={customerID}
                phoneNumber={customerPhoneNumber}
                customerName={customerName}
                isModalVisible={isDeductUserWalletModalVisible}
                handleCloseDialog={closeDeductUserWalletDialog}
            />

            <div className="card border-0 m-3 rounded" style={{ background: "#FFF" }}>
                <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="tab-header-container">
                            <img src={WalletIcon} alt="wallet" />
                            Wallet Details
                        </div>
                        <div className="d-flex gap-2">
                            <button
                                className="customer_tab_btn_outline"
                                type="button"
                                onClick={(e) => openDeductUserWalletDialog()}
                            >
                                <img src={DeductIcon} alt="deduct" />
                                Deduct Wallet
                            </button>
                            <button
                                className="customer_tab_btn"
                                type="button"
                                onClick={(e) => openTopupUserWalletDialog()}
                            >
                                <img src={FundIcon} alt="fund" />
                                Fund Wallet
                            </button>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="header"></span>
                    </div>

                    <div className="customer-overview-container pt-3">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row mb-4 gap-3">
                                <div className="customer-info-card">
                                    <div className="top-section">
                                        <div className="info-card-icon">
                                            <img src={Balance} alt="icon" />
                                        </div>
                                        <span>Balance</span>
                                    </div>
                                    <span className="bottom-section">
                                        ₦
                                        {accountBalance === ""
                                            ? "..."
                                            : parseFloat(accountBalance).toLocaleString("en")}
                                    </span>
                                </div>

                                <div className="customer-info-card">
                                    <div className="top-section">
                                        <div className="info-card-icon">
                                            <img src={Funded} alt="icon" />
                                        </div>
                                        <span>Total Funded</span>
                                    </div>
                                    <span className="bottom-section">
                                        ₦
                                        {accountBalance === ""
                                            ? "..."
                                            : parseFloat(accountBalance).toLocaleString("en")}
                                    </span>
                                </div>

                                <div className="customer-info-card">
                                    <div className="top-section">
                                        <div className="info-card-icon">
                                            <img src={Spent} alt="icon" />
                                        </div>
                                        <span>Total Spent</span>
                                    </div>
                                    <span className="bottom-section">
                                        ₦
                                        {totalSpent === ""
                                            ? "..."
                                            : parseFloat(totalSpent).toLocaleString("en")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card border-0 mt-4" style={{ background: "transparent" }}>
                <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="tab-header-container">
                            <img src={TransactionIcon} alt="wallet" />
                            Wallet History
                        </div>
                        <div></div>
                    </div>

                    {isLoading === false ? null : (
                        <LoadingContainer>
                            <LoadingSpinner className="spinner-border spinner-grow-sm" />
                        </LoadingContainer>
                    )}

                    {isEmpty === false ? (
                        <div className="shipments-table-container">
                            <table className="shipments-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                {isLoading === true ? null : (
                                    <tbody>
                                        {walletHistory.map((history) => {
                                            const dateTime = moment(history.date_time);
                                            const timestamp = dateTime.format("ddd MMM Do, h:mm A");
                                            const color = history.type === "credit" ? "green" : "red";
                                            return (
                                                <tr
                                                    style={{ fontSize: "14.4px" }}
                                                    key={history.id}
                                                    onClick={() => handleClick(history.id)}
                                                >
                                                    <td>{history.description}</td>
                                                    <td>
                                                        ₦{parseFloat(history.amount).toLocaleString("en")}
                                                    </td>
                                                    <td>
                                                        <GoPrimitiveDot style={{ color: color }} />
                                                        {history.type}
                                                    </td>
                                                    <td>{timestamp}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    ) : (
                        <EmptyContainer>
                            <p>Oops... Sorry, customer has no wallet history</p>
                        </EmptyContainer>
                    )}
                    {isRetrievingMore === false ? null : (
                        <div style={{ textAlign: "center", marginTop: 40 }}>
                            <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                            Retrieving More..
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default CustomerWallet;
