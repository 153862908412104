import revenueIcon from "../../images/revenue.jpg";
import packageIcon from "../../images/package.jpg";
import cardIcon from "../../images/card.png";
import userIcon from "../../images/user.png";
import Money from "../../assets/dashboard/payments.svg";
import Box from "../../assets/dashboard/deployed_code.svg";
import Customers from "../../assets/dashboard/group.svg";
import Stack from "../../assets/dashboard/database.svg";

const OverallStatistics = ({totalRevenue, totalShipments, totalCustomers, totalTransactions}) => {
  return (
    <div className=" w-100 p-2 gap-3 g-2 top-cards" style={{backgroundColor: "#FFF", borderRadius: "16px"}} >
      <div className="col-md-6 w-100 card-item col-xl-3">
        <div className="w-100" >
          <div className="mb-4" >
            <div className="d-flex justify-content-center">
              <div className="card-icon" >
                <img src={Money} alt="money" />
              </div>
            </div>
            <p className="card-title" >Total Revenue</p>
            <p className="card-sub" >Sum total of all revenue</p>
          </div>
          <div className="d-flex justify-content-center gap-3" >
            <p className="card-data" >&#8358; {totalRevenue}</p>
          </div>
        </div>
      </div>

      <div className="col-md-6 w-100 card-item col-xl-3">
        <div className="w-100" >
          <div className="mb-4" >
            <div className="d-flex justify-content-center">
              <div className="card-icon" >
                <img src={Box} alt="box" />
              </div>
            </div>
            <p className="card-title" >Shipments</p>
            <p className="card-sub" >Total of all shipments</p>
          </div>
          <div className="d-flex justify-content-center gap-3" >
            <p className="card-data" >{totalShipments}</p>
          </div>
        </div>
      </div>

      <div className="col-md-6 w-100 card-item col-xl-3">
        <div className="w-100" >
          <div className="mb-4" >
            <div className="d-flex justify-content-center">
              <div className="card-icon" >
                <img src={Customers} alt="customers" />
              </div>
            </div>
            <p className="card-title" >Customers</p>
            <p className="card-sub" >Total of all customers</p>
          </div>
          <div className="d-flex justify-content-center gap-3" >
            <p className="card-data" >{totalCustomers}</p>
          </div>
        </div>
      </div>

      <div className="col-md-6 w-100 card-item col-xl-3">
        <div className="w-100" >
          <div className="mb-4" >
            <div className="d-flex justify-content-center">
              <div className="card-icon" >
                <img src={Stack} alt="stack" />
              </div>
            </div>
            <p className="card-title" >Total Transactions</p>
            <p className="card-sub" >Sum total of all transactions</p>
          </div>
          <div className="d-flex justify-content-center gap-3" >
            <p className="card-data" >{totalTransactions}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default OverallStatistics;
