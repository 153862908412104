import { useEffect, useState } from "react";
import "../styles/admin.scss";
import axios from "axios";
import { getAllAdminsURL, searchEmployeesUrl } from "../library/URLs";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from "react-icons/hi";
import * as React from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import * as BsIcon from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";
import SuspendAdminDialog from "../modals/SuspendAdminDialog";
import UnsuspendAdminDialog from "../modals/UnsuspendAdminDialog";
import DeleteAdminDialog from "../modals/DeleteAdminDialog";
import { SessionManagement } from "../library/SessionManagement";
import AdminIcon from "../assets/admins/group.svg";
import { SearchBox } from "../components/InputFields";
import SuperAdminIcon from "../assets/super-admin.png";
import AdminTypeIcon from "../assets/admin.png";
import { useRef } from "react";

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

const Admins = () => {
  const form = useRef(null);
  const navigator = useNavigate();

  const [admins, setAdmins] = useState([]);
  const [query, setQuery] = useState("");
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [userActionMenuVisibility, setUserActionMenuVisibility] = useState();
  const [deleteAdminOpen, setDeleteAdminOpen] = useState(null);
  const [isUnsuspendAdminModalVisible, setIsUnsuspendAdminModalVisible] =
    useState(null);
  const [isSuspendAdminModalVisible, setIsSuspendAdminModalVisible] =
    useState(null);

  const [isQuerySearching, setIsQuerySearching] = useState(false);
  const [resultContainerVisibility, setResultContainerVisibility] =
    useState(false);
  const [employeeQueryResult, setEmployeeQueryResult] = useState([]);

  const navigate = useNavigate();

  const toggleUserActionMenu1 = (id) => {
    if (userActionMenuVisibility === id) {
      setUserActionMenuVisibility(null);
    } else {
      setUserActionMenuVisibility(id);
    }
  };

  const handleCloseModal = () => {
    setIsUnsuspendAdminModalVisible(null);
    setIsSuspendAdminModalVisible(null);
    setDeleteAdminOpen(null);
  };

  const handleOpenModal = (id, action) => {
    if (action === "delete") {
      setDeleteAdminOpen(id);
    } else if (action === "suspend") {
      setIsSuspendAdminModalVisible(id);
    } else if (action === "unsuspend") {
      setIsUnsuspendAdminModalVisible(id);
    }
  };

  const data = {
    limit: 6,
    offset: 0,
  };

  const newData = { ...data, ...UserProfileData() };

  const fetchAllAdmins = () => {
    axios
      .post(getAllAdminsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrieving(false);

        if (response["data"]["success"] === false) return;

        const transactions = response["data"]["data"];

        if (transactions.length === 0) setIsEmpty(true);

        setAdmins(transactions);
      });
  };

  useEffect(() => {
    fetchAllAdmins();
  }, []);

  const handleClick = (adminID) => {
    navigator("/admin/" + adminID);
  };

  const searchForEmployee = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = () => {
    const formData = new FormData(form.current);
    const query = formData.get("query");
    setQuery(query);

    if (query.toString().trim() === "") {
      setIsQuerySearching(false);
      setResultContainerVisibility(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);

    axios.post(searchEmployeesUrl, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setEmployeeQueryResult(customers);
    });
  };

  const goToSingleAdmin = (adminID) => {
    navigator("/admin/" + adminID);
  };

  const toggleUserActionMenu = () => {
    console.log("hello");
  };

  return (
    <div className="home">
      <Sidebar />

      <DeleteAdminDialog
        isModalVisible={deleteAdminOpen !== null}
        handleCloseDialog={handleCloseModal}
        adminID={deleteAdminOpen}
        updateEmployees={fetchAllAdmins}
      />

      <UnsuspendAdminDialog
        isModalVisible={isUnsuspendAdminModalVisible !== null}
        handleCloseDialog={handleCloseModal}
        customerID={isUnsuspendAdminModalVisible}
      />

      <SuspendAdminDialog
        isModalVisible={isSuspendAdminModalVisible !== null}
        handleCloseDialog={handleCloseModal}
        customerID={isSuspendAdminModalVisible}
      />
      <div className="main">
        <Navbar />
        {/*{isError === true ? (*/}
        {/*    <ErrorNotification message={errorMessage}>*/}
        {/*        This is a notification*/}
        {/*    </ErrorNotification>*/}
        {/*) : null}*/}

        {/*{isSuccess === true ? (*/}
        {/*    <SuccessNotification message={successMessage}>*/}
        {/*        This is a notification*/}
        {/*    </SuccessNotification>*/}
        {/*) : null}*/}
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={AdminIcon} alt="group" />
                  <h5 className="m-0">Admins</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <form
                    className="query-result-container "
                    ref={form}
                    onSubmit={searchForEmployee}
                  >
                    <SearchBox
                      onChange={handleQueryChange}
                      name="query"
                      value={query}
                      placeholder="Search by admin name"
                    />
                    <div
                      className={`query-result ${
                        resultContainerVisibility === true
                          ? "result-visible"
                          : null
                      }`}
                    >
                      {setIsQuerySearching === true
                        ? null
                        : employeeQueryResult?.map((employee) => {
                            return (
                              <div
                                key={employee.id}
                                className="d-flex flex-column"
                                onClick={() => handleClick(employee.id)}
                              >
                                <span style={{ fontSize: 15 }}>
                                  {employee.name}
                                </span>
                                <span className="d-flex align-items-center">
                                  <span style={{ fontSize: 13 }}>
                                    {employee.phone}
                                  </span>
                                  <BsIcon.BsDot />
                                  <span style={{ fontSize: 13 }}>
                                    {employee.email}
                                  </span>
                                </span>
                              </div>
                            );
                          })}
                    </div>
                  </form>
                  <button
                    onClick={() => navigator("/create-admin")}
                    style={{ fontSize: 12 }}
                    className=" btn-primary"
                  >
                    Create Admin
                  </button>
                </div>
              </div>
              <div className="row mt-4 admins">
                <div className="row mt-4 pr-none">
                  {admins.map((admin) => {
                    return (
                      <div
                        key={admin.id}
                        className="col-xl-3 col-md-6 mb-3 mr-3 pr-none"
                      >
                        <div className="admin-card">
                          <div
                            style={{ padding: 0 }}
                            className="card-body shipper-container"
                          >
                            <div className="d-flex flex-column align-items-center">
                              <div
                                style={{ width: "100%" }}
                                className="d-flex justify-content-end"
                              >
                                <div className="user-menu-container">
                                  <HiOutlineDotsVertical
                                    onClick={() =>
                                      toggleUserActionMenu1(admin.id)
                                    }
                                    style={{
                                      width: 20,
                                      fontSize: 14,
                                      cursor: "pointer",
                                      marginRight: 10,
                                    }}
                                    className="icon"
                                  />
                                  <div
                                    className={`user-menu ${
                                      userActionMenuVisibility === admin.id
                                        ? "user-menu-visible"
                                        : null
                                    }`}
                                  >
                                    {SessionManagement.getUserPrivileges()
                                      ?.edit_employee === 1 ? (
                                      <span
                                        onClick={() =>
                                          navigate(`/admin/edit/${admin.id}`)
                                        }
                                      >
                                        Edit Admin Details
                                      </span>
                                    ) : null}
                                    {SessionManagement.getUserPrivileges()
                                      ?.delete_employee === 1 ? (
                                      <span
                                        onClick={() =>
                                          handleOpenModal(admin.id, "delete")
                                        }
                                      >
                                        Delete Admin
                                      </span>
                                    ) : null}
                                    {/* <span onClick={()=>handleOpenModal(admin.id, "suspend")} >Suspend Admin</span> */}
                                    {/* <span>Deduct From Wallet</span> */}
                                  </div>
                                </div>
                              </div>
                              <ProfileImage
                                onClick={(e) => goToSingleAdmin(admin.id)}
                                src={admin.profile_image_url}
                              />
                              <div className="mt-4">
                                <div className="d-flex justify-content-center">
                                  <div
                                    className={`admin-type 
                                                                    ${
                                                                      admin.employee_type ===
                                                                      "Admin"
                                                                        ? "admin-type-admin"
                                                                        : "admin-type-super-admin"
                                                                    }`}
                                  >
                                    {admin.employee_type === "Admin" ? (
                                      <img src={AdminTypeIcon} alt="icon" />
                                    ) : (
                                      <img src={SuperAdminIcon} alt="icon" />
                                    )}
                                    {admin.employee_type}
                                  </div>
                                </div>
                                <p className="admin-name">{admin.name}</p>
                                <p
                                  className="admin-email"
                                  style={{ fontSize: 12 }}
                                >
                                  {admin.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
