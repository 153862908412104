import styled from "styled-components";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import * as React from "react";
import { useState } from "react";
import MakeRegularUserDialog from "../../modals/MakeRegularUserDialog";
import MarkShipmentAsPaidDialog from "../../modals/MarkShipmentAsPaidDialog";
import { BsCheckAll, BsExclamationCircleFill } from "react-icons/bs";
import { IoMdHelpCircle } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";
import {
  getShipmentPackagesURL,
  getShipmentServicesUrl,
} from "../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../library/constants";
import PackageItemsDialog from "../../modals/PackageItemsDialog";
import InfoIcon from "../../assets/single-shipment/inventory_2.svg";

const LoadingContainer = styled.div`
  height: 165px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Unpaid = styled.div`
  color: #924040;
  width: 95px;
  background-color: #fde1e1;
  padding: 2px 10px 2px 10px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  text-align: center;
`;

const Paid = styled.div`
  width: 95px;
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 2px 10px 2px 10px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  text-align: center;
`;

const ShipmentInformation = ({
  isRetrieving,
  amount,
  description,
  paymentMethod,
  paymentStatus,
  approvedBy,
  processor,
  itemValue,
  itemValueCurrency,
  weight,
  shipmentID,
  shipmentNumber,
  currency,
  station,
  pickupFee,
  requestedPickup,
  pickupRequestStatus,
  shipmentType,
  shipmentPlan,
  isDiscrepancy,
  weightDiscrepancy,
  insurance,
}) => {
  const [showMarkShipmentAsPaidDialog, setShowMarkShipmentAsPaidDialog] =
    useState(false);

  const [showPackages, setShowPackages] = useState(false);
  const [packageItems, setPackageItems] = useState([]);
  const [shipmentServices, setShipmentServices] = useState([]);

  const data = {
    shipment_number: shipmentNumber,
  };

  const newData = { ...data, ...UserProfileData() };

  const fetchShipmentPackages = () => {
    console.log("sdfafw", shipmentID);
    axios
      .post(getShipmentPackagesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        console.log("poas", response["data"]);
        setPackageItems(response["data"]);
        setShowPackages(true);
      });
  };

  React.useEffect(() => {
    getShipmentServices();
    // eslint-disable-next-line
  }, [shipmentNumber]);

  const getShipmentServices = async () => {
    axios
      .post(
        getShipmentServicesUrl,
        { ...newData, shipment_no: shipmentNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response["data"]["success"] === false) return;

        setShipmentServices(response["data"]["data"]);
      });
  };

  const toggleMarkShipmentAsPaidDialog = () => {
    setShowMarkShipmentAsPaidDialog(!showMarkShipmentAsPaidDialog);
    console.log("hello");
  };

  const handleCloseModal = () => {
    setShowMarkShipmentAsPaidDialog(false);
  };

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Click to view all packages
  //   </Tooltip>
  // );

  return (
    <div className="col-12">
      <MarkShipmentAsPaidDialog
        isModalVisible={showMarkShipmentAsPaidDialog}
        handleCloseDialog={handleCloseModal}
        shipmentID={shipmentID}
      />

      <PackageItemsDialog
        packages={packageItems}
        isModalVisible={showPackages}
        handleCloseDialog={() => setShowPackages(false)}
      />

      {isRetrieving === true ? (
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm" />
        </LoadingContainer>
      ) : (
        <div className="section-wrap-secondary">
          <div className="top-banner">
            <img src={InfoIcon} alt="icon" />
            <p className="m-0">Shipment Information</p>
          </div>
          <div className=" shipper-container shipment-info">
            <div className="row">
              <div className="col-xl-3">
                <span className="info-label">Amount</span>
                <p className="info-value">
                  {currency === "USD"
                    ? "$"
                    : currency === "NGN"
                    ? "₦"
                    : currency === "GBP"
                    ? "£"
                    : currency === "EUR"
                    ? "€"
                    : "₦"}
                  {parseFloat(amount).toLocaleString("en")}
                </p>
              </div>
              <div className="col-xl-3">
                <span className="info-label">Payment Method</span>
                <p className="info-value">{paymentMethod}</p>
              </div>
              <div className="col-xl-3">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <span className="info-label">Payment Status</span>
                  {paymentStatus === "Unpaid" ? (
                    <BsCheckAll
                      onClick={toggleMarkShipmentAsPaidDialog}
                      style={{ width: 20, cursor: "pointer", color: "green" }}
                    />
                  ) : null}
                </div>
                {paymentStatus === "" ? null : paymentStatus === "Paid" ? (
                  <Paid>{paymentStatus}</Paid>
                ) : (
                  <Unpaid>{paymentStatus}</Unpaid>
                )}
              </div>
              <div className="col-xl-3">
                <span className="info-label">Weight</span>
                <p className="info-value">
                  {weight === null || weight === "" ? "None" : weight + "KG"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3">
                <span className="info-label">Item Value</span>
                <p className="info-value">
                  {itemValue === null || itemValue === ""
                    ? "None"
                    : itemValueCurrency +
                      " " +
                      parseFloat(itemValue).toLocaleString("en")}
                </p>
              </div>
              <div className="col-xl-3">
                <span className="info-label">Transporter</span>
                <p className="info-value">{processor}</p>
              </div>
              <div className="col-xl-4">
                <span className="info-label">Approved By</span>
                <p className="info-value">{approvedBy}</p>
              </div>
              {station && (
                <div className="col-xl-3">
                  <span className="info-label">Processing Station</span>
                  <p className="info-value">{station}</p>
                </div>
              )}
              {requestedPickup === "Yes" && (
                <div className="col-xl-3">
                  <span className="info-label">Pickup Fee</span>
                  <p className="info-value">
                    ₦{parseFloat(pickupFee).toLocaleString("en")}
                  </p>
                </div>
              )}
              {requestedPickup === "Yes" && (
                <div className="col-xl-3">
                  <span className="info-label">Pickup Request Status</span>
                  <p className="info-value">{pickupRequestStatus}</p>
                </div>
              )}
              <div className="col-xl-3">
                <span className="title">Insurance</span>
                <p>{insurance}</p>
              </div>
              {shipmentType === "Local" &&
                UserProfileData()?.company_id === "123" && (
                  <div className="col-xl-3">
                    <span className="title">Local Pickup Plan</span>
                    <p>{shipmentPlan}</p>
                  </div>
                )}
              {UserProfileData()?.company_id === "123" && (
                <div className="col-xl-3">
                  <span className="title">Requested Pickup</span>
                  <p>{requestedPickup}</p>
                </div>
              )}
              {isDiscrepancy === "Yes" && (
                <div className="col-xl-3">
                  <span className="title">DHL Weight</span>
                  <Tooltip title="Detected Weight Discrepancy" placement="top">
                    <span className="d-flex align-items-start gap-1">
                      <p>{weightDiscrepancy} KG</p>
                      <BsExclamationCircleFill
                        style={{ fontSize: 14, color: "red" }}
                      />
                    </span>
                  </Tooltip>
                </div>
              )}
              <div
                className="col-xl-12"
                style={{ cursor: "pointer" }}
                onClick={() => fetchShipmentPackages()}
              >
                <span className="info-label d-flex gap-1 align-items-start">
                  Description
                  <div className="position-relative">
                    <Tooltip
                      title="Click here to view packages"
                      placement="top"
                    >
                      <span>
                        <IoMdHelpCircle />
                      </span>
                    </Tooltip>
                  </div>
                </span>
                <p className="info-value">{description}</p>
              </div>
              {shipmentServices?.length > 0 && (
                <div className="col-xl-12">
                  <span className="info-label">Additional Services</span>
                  <p className="info-value">
                    {shipmentServices
                      ?.map((service) => service?.service_name)
                      ?.join(", ")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentInformation;
