import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  uploadPackageImgUrl,
  updateCarrierTypeUrl
} from "../library/URLs";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, SelectTextBox2, TextBox, TextFieldBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";
import countries from "../files/countries.json";
import currencies from "../files/currencies.json";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor: pointer;
  padding-left: 2px;

  svg {
    font-size: 18px;
  }
`;

const UpdateCarrierTypeDialog = ({
  isModalVisible,
  handleCloseDialog,
  carrier_details
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const [carrierForm, setCarrierForm] = useState({
    shipment_type: "",
    carrier: "",
    connection_type: "",
    api_type: "",
    transit_time: "",
    display_name: "",
    pricing_type: "",
    additional_info: "",
    rate_document_url: "",
    image_url: "",
    per_kg_countries: [
      {
        countries: "",
        minimum_weight: "",
        maximum_weight: "",
        price: "",
        currency: "NGN",
      },
    ],
  });
  const [logoFile, setLogoFile] = useState("");
  const [rateDocFile, setRateDocFile] = useState("");

  const logoInputRef = useRef();
  const rateDocInputRef = useRef();

  useEffect(() => {
    if(carrier_details) {
      setCarrierForm({
        ...carrierForm,
        carrier_id: carrier_details?.id,
        shipment_type: carrier_details?.shipment_type,
        carrier: carrier_details?.carrier,
        pricing_type: carrier_details?.pricing_type,
        connection_type: carrier_details?.connection_type,
        api_type: carrier_details?.api_type,
        transit_time: carrier_details?.transit_time,
        display_name: carrier_details?.masked_name,
        additional_info: carrier_details?.additional_info,
        image_url: carrier_details?.image_url,
        rate_document_url: carrier_details?.rate_document_url,
        per_kg_countries: carrier_details ? carrier_details?.per_kg_countries?.map((carrier) => {return {
          countries: carrier?.country,
          price: carrier?.price_per_kg,
          minimum_weight: carrier?.minimum_weight,
          maximum_weight: carrier?.maximum_weight,
          currency: carrier?.currency
        }}) : []
      });

      if(carrier_details?.image_url) {
        setLogoFile(carrier_details?.masked_name);
      };

      if(carrier_details?.rate_document_url) {
        setRateDocFile(carrier_details?.masked_name);
      }

      fetchApiCarriers();
    }
    // eslint-disable-next-line
  }, [carrier_details])

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (carrierForm?.shipment_type) {
            let api_carriers = res?.data?.data
              ?.filter((carrier) => carrier[carrierForm?.shipment_type?.toLowerCase()] === "Yes")
              ?.map((carrier) => {
                return {
                  label: carrier?.name,
                  value: `${carrier?.id}`,
                };
              });
            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {});
  };

  const [uploadingLogo, setUploadingLogo] = useState(false);

  const uploadLogoImage = (file) => {
    const imgFormData = new FormData();
    imgFormData.append("image", file);

    uploadingLogo(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploadingLogo(false);
        setCarrierForm({
          ...carrierForm,
          image_url: response?.data,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const [uploadingRateDoc, setUploadingRateDoc] = useState(false);

  const uploadRateDocument = (file) => {
    const imgFormData = new FormData();
    imgFormData.append("image", file);

    setUploadingRateDoc(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploadingRateDoc(false);
        setCarrierForm({
          ...carrierForm,
          rate_document_url: response?.data,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if(name === "api_type") {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
        image_url: apiCarriers?.find(api => `${api?.value}` === value)?.img_url
      });
      setLogoFile(apiCarriers?.find(api => `${api?.value}` === value)?.label);
    } else {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
      });
    }
  };

  const triggerLogoFileInput = () => {
    logoInputRef.current.click();
  };

  const triggerRateDocInput = () => {
    rateDocInputRef.current.click();
  };

  const handleLogoInput = (e) => {
    const { files } = e.target;
    setLogoFile(files[0]?.name);
    uploadLogoImage(files[0]);
  };

  const handleRateDocInput = (e) => {
    const { files } = e.target;
    setRateDocFile(files[0]?.name);
    uploadRateDocument(files[0]);
  };

  const connection_type_options = [
    { label: "Custom", value: "Custom" },
    { label: "API", value: "API" },
    { label: "Manual", value: "Manual" },
  ];

  // const pricing_type_options = [
  //   { label: "Default", value: "Default" },
  //   { label: "Per KG", value: "Per KG" },
  // ];

  const country_options = countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  const currency_options = currencies
    .filter(
      (currency) =>
        currency.cc === "USD" ||
        currency.cc === "NGN" ||
        currency.cc === "GBP" ||
        currency.cc === "EUR"
    )
    ?.map((currency) => {
      return {
        label: `${currency.name} - ${currency.cc}`,
        value: currency.cc,
      };
    });

  const addWeightRate = () => {
    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy.push({
      countries: "",
      minimum_weight: "",
      maximum_weight: "",
      price: "",
      currency: "NGN",
    });

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const removeWeightRate = () => {
    if (carrierForm.per_kg_countries.length < 1) {
      return;
    }

    const per_kg_copy = [...carrierForm.per_kg_countries];
    per_kg_copy.pop();
    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const handleWeightRateInput = (e, id) => {
    const { name, value } = e.target;
    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      [name]: value
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const handleSelectWeightRateInput = (id, name, value) => {

    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      [name]: value
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      carrier: carrierForm?.api_type,
      pricing_type: carrierForm?.connection_type === "Custom" ? "Per KG" : "Default"
    };
    setIsSubmitting(true);
    
    axios
      .post(updateCarrierTypeUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if(res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier updated successfully!");

          setTimeout(() => {
              setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
              window.location.reload();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
              setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPDATE CARRIER</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a container</p>
        <div className="query-result-container">
          {/* <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Carrier
            </label>
            <TextBox
              type="text"
              name="carrier"
              value={carrierForm.carrier}
              onChange={handleInput}
              placeholder="Enter Carrier Name"
            />
          </div> */}
          <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Shipment Type
            </label>
            <TextBox
              type="text"
              name="shipment_type"
              disabled
              value={carrierForm.shipment_type}
              onChange={handleInput}
              placeholder="Enter Shipment Type"
            />
          </div>
          <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Connection Type
            </label>
            <SelectBox
              name="connection_type"
              value={carrierForm?.connection_type}
              options={connection_type_options}
              onChange={handleInput}
              placeholder="Enter Connection Type"
            />
          </div>
          {/* <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Carrier
            </label>
            <SelectBox
              name="carrier"
              value={carrierForm?.carrier}
              options={apiCarriers}
              onChange={handleInput}
              placeholder="Enter Carrier"
            />
          </div> */}
          {carrierForm?.connection_type === "API" && (
            <div className="mb-2" >
              <label htmlFor="Description" className="mb-2">
                API Type
              </label>
              <SelectBox
                name="api_type"
                value={carrierForm?.api_type}
                options={apiCarriers}
                onChange={handleInput}
                placeholder="Enter Connection Type"
              />
            </div>
          )}
          <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Display Name
            </label>
            <TextBox
              type="text"
              name="display_name"
              value={carrierForm.display_name}
              onChange={handleInput}
              placeholder="Enter Carrier Display Name"
            />
          </div>
          <div className="mb-2" >
            <label className="mb-2" htmlFor="Description">
              Transit Time
            </label>
            <TextBox
              name="transit_time"
              type="text"
              value={carrierForm.transit_time}
              onChange={handleInput}
              placeholder="E.g: 2 to 3 working days"
            />
          </div>
          {carrierForm.connection_type === "Manual" && (
            <div className="mb-2" >
              <label htmlFor="package img">Upload Carrier Rates Document</label>
              <div className="d-flex gap-2 align-items-center">
                {uploadingRateDoc && (
                  <span className="spinner-border spinner-grow-sm" />
                )}
                <div className="w-100">
                  <input
                    style={{
                      visibility: "hidden",
                      position: "fixed",
                      bottom: 0,
                      zIndex: -1,
                    }}
                    type="file"
                    onChange={handleRateDocInput}
                    ref={rateDocInputRef}
                    accept=".xlsx, .xls"
                    name=""
                    id=""
                  />
                  <div
                    style={{ marginTop: 20 }}
                    onClick={triggerRateDocInput}
                    className="change-password-button"
                  >
                    Choose Spreadsheet
                  </div>
                </div>
              </div>
              {carrierForm?.rate_document_url !== "" && (
                <small>{rateDocFile}</small>
              )}
            </div>
          )}
          <div className="mb-2" >
            <label htmlFor="package img">Upload Carrier Logo</label>
            <div className="d-flex gap-2 align-items-center">
              {uploadingLogo && (
                <span className="spinner-border spinner-grow-sm" />
              )}
              <div className="w-100">
                <input
                  style={{
                    visibility: "hidden",
                    position: "fixed",
                    bottom: 0,
                    zIndex: -1,
                  }}
                  type="file"
                  onChange={handleLogoInput}
                  ref={logoInputRef}
                  accept="image/*"
                  name=""
                  id=""
                />
                <div
                  style={{ marginTop: 20 }}
                  onClick={triggerLogoFileInput}
                  className="change-password-button"
                >
                  Choose Image
                </div>
              </div>
            </div>
            {carrierForm?.image_url !== "" && <small>{logoFile}</small>}
          </div>
          <div className="mb-2" >
            <label htmlFor="Description" className="mb-2">
              Additional Information
            </label>
            <TextFieldBox
              name="additional_info"
              value={carrierForm.additional_info}
              onChange={handleInput}
              placeholder="Enter Carrier Display Name"
            />
          </div>
          {carrierForm?.connection_type === "Custom" && (
            <div className="mb-3" >
              {
                carrierForm.per_kg_countries?.map((kg_item, id) => (
                  <div key={id} >
                    <div className="mb-2" >
                      <label className="mb-2" htmlFor="Description">
                        Country
                      </label>
                      <SelectTextBox2
                        name="countries"
                        value={kg_item?.countries}
                        options={country_options}
                        onChange={handleSelectWeightRateInput}
                        id={id}
                        placeholder="Enter Connection Type"
                      />
                    </div>
                    <Row className='mb-2' >
                      <Col sm={12} md={6} >
                        <div className="mb-2" >
                          <label className="mb-2" htmlFor="Description">
                            Minimum Weight
                          </label>
                          <TextBox
                            name="minimum_weight"
                            type="text"
                            value={kg_item?.minimum_weight}
                            onChange={(e) => handleWeightRateInput(e, id)}
                            placeholder="KG"
                            prefix="KG"
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={6} >
                        <div className="mb-2" >
                          <label className="mb-2" htmlFor="Description">
                            Maximum Weight
                          </label>
                          <TextBox
                            name="maximum_weight"
                            type="text"
                            value={kg_item?.maximum_weight}
                            onChange={(e) => handleWeightRateInput(e, id)}
                            placeholder="KG"
                            prefix="KG"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-2" >
                      <label className="mb-2" htmlFor="Description">
                        Currency
                      </label>
                      <SelectBox 
                        name="currency"
                        value={kg_item?.currency}
                        onChange={(e) => handleWeightRateInput(e, id)}
                        placeholder="Select Currency"
                        options={currency_options}
                      />
                    </div>
                    <div className="mb-2" >
                      <label className="mb-2" htmlFor="Description">
                        Price
                      </label>
                      <TextBox
                        name="price"
                        type="text"
                        value={kg_item?.price}
                        onChange={(e) => handleWeightRateInput(e, id)}
                        prefix={kg_item?.currency ?? ""}
                        placeholder="Enter Fee"
                      />
                    </div>
                  </div>
                ))
              }
              
              <div className="d-flex gap-3 align-items-center mb-2">
                <div className="">
                  <ContentFieldsActionButton
                  onClick={addWeightRate}
                  >
                    +
                  </ContentFieldsActionButton>
                </div>
                <div className="">
                  <ContentFieldsActionButton
                  onClick={removeWeightRate}
                  >
                    -
                  </ContentFieldsActionButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={onSubmit}
          type="submit"
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateCarrierTypeDialog;
