import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight, BsQuestionCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { CheckBox, RadioCheck } from "../../../components/InputFields";

const ParcelConnect = ({ prevPage, answers, setAnswers }) => {
  const PageHeader = styled.h4`
    font-family: Prompt, sans-serif;
    font-size: 32px;
    line-height: 28.8px;
    margin-bottom: 8px;
    color: #000;

    @media (max-width: 556px) {
      font-size: 2.4rem;
    }
  `;

  const PageSubheader = styled.h4`
    font-size: 14px;
    color: #667185;
    margin-bottom: 48px;
    font-weight: normal;
  `;

  const SubLight = styled.span`
    color: #667185;
  `

  const que1Answer = (answer) => {
    setAnswers({
      ...answers,
      parcelflow_connect: {
        ...answers?.parcelflow_connect,
        new_logistics: answer
      }
    })
  }

  const que2Answer = (answer) => {
    setAnswers({
      ...answers,
      parcelflow_connect: {
        ...answers?.parcelflow_connect,
        process_shipments: answer
      }
    })
  }

  // const que3Answer = (answer) => {
  //   setAnswers({
  //     ...answers,
  //     parcelflow_connect: {
  //       ...answers?.parcelflow_connect,
  //       have_website: answer
  //     }
  //   })
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("parcelconnect answers ", answers);
  };

  return (
    <>
      <PageHeader className="page-header">Operations - <SubLight>Parcelflow Connect</SubLight></PageHeader>
      <PageSubheader>
        This information will be used to assist you in setting up your account and would never be shared
      </PageSubheader>
      <div className="progress-bar">
        <div className={`progress-bar-inactive progress-bar-active`}></div>
        <div className={`progress-bar-active`}></div>
      </div>
      <form>
        <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            Are you new logistics?
          </div>
          <ul className="multi-answer" >
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.new_logistics === "Yes"}
                onChange={()=>que1Answer("Yes")}
              />
              Yes
            </li>
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.new_logistics === "No"}
                onChange={()=>que1Answer("No")}
              />
              No
            </li>
          </ul>
        </div>
        {/* <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            How do you currently process your shipment? 
          </div>
          <ul className="multi-answer" >
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.new_logistics === "My account with carriers like DHL, FedEx e.t.c"}
                onChange={()=>que1Answer("My account with carriers like DHL, FedEx e.t.c")}
              />
              My account with carriers like DHL, FedEx e.t.c
            </li>
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.new_logistics === "Other people account with carriers"}
                onChange={()=>que1Answer("Other people account with carriers")}
              />
              Other people&apos;s account with carriers
            </li>
          </ul>
        </div> */}
        <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            If No, what service do you use to process your shipments?
          </div>
          <ul className="multi-answer" >
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.process_shipments === "My account with DHL, FedEx, UPS"}
                onChange={()=>que2Answer("My account with DHL, FedEx, UPS")}
              />
              My account with carriers like DHL, FedEx e.t.c
            </li>
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.process_shipments === "Other people account with carriers"}
                onChange={()=>que2Answer("Other people account with carriers")}
              />
              Other people&apos;s account with carriers
            </li>
            {/* <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.process_shipments === "Not applicable"}
                onChange={()=>que2Answer("Not applicable")}
              />
              Not applicable
            </li> */}
          </ul>
        </div>
        {/* <div className="question-wrap mb-3" >
          <div className="question-text" >
            <BsQuestionCircleFill />
            Do you have a website your customers can book orders from?
          </div>
          <ul className="multi-answer" >
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.have_website === "Yes"}
                onChange={()=>que3Answer("Yes")}
              />
              Yes
            </li>
            <li>
              <RadioCheck 
                checked={answers?.parcelflow_connect?.have_website === "No"}
                onChange={()=>que3Answer("No")}
              />
              No
            </li>
          </ul>
        </div> */}
        <div className="d-flex flex-row my-5 gap-4 justify-content-end">
          <button type="button" className="btn-secondary" onClick={prevPage} >
            <BsChevronLeft />
          </button>
          <button type="submit" onClick={handleSubmit} className="login-button btn-primary rounded">
            Proceed <BsChevronRight />
          </button>
        </div>

        {/* <PageSubheader>
                      Already have an account? <a href="/login">Login</a>
                    </PageSubheader> */}
      </form>
    </>
  );
};

export default ParcelConnect;
