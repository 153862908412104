import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {
  getAllCustomerWalletURL,
  getTransactionsURL,
  searchForCustomersURL,
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import { BsDot } from "react-icons/bs";
import WalletIcon from "../assets/sidebar/CustomerWallets.svg";
import { SearchBox } from "../components/InputFields";
import Pagination from "../components/Pagination";
import ExportIcon from "../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CustomersWallet = () => {
  const form = useRef(null);
  const navigator = useNavigate();
  const params = useParams();

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);
  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);

  const [searchParam] = useSearchParams();

  const page = searchParam.get("page");

  useEffect(() => {
    if (page) {
      getCustomerWallets();
    }
    // eslint-disable-next-line
  }, [page]);

  const [isQuerySearching, setIsQuerySearching] = useState(false);
  const [resultContainerVisibility, setResultContainerVisibility] =
    useState(false);
  const [customerQueryResults, setCustomerQueryResults] = useState([]);

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [openExport, setOpenExport] = useState(null);

  const [wallets, setWallets] = useState([]);

  const getCustomerWallets = async () => {
    const data = {
      limit: 50,
      payment_status: chosenPaymentStatus,
      date: chosenDate,
      paginated: "Yes",
      page: page ?? "1",
    };

    const newData = { ...data, ...UserProfileData() };

    await axios
      .post(getAllCustomerWalletURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newWallets = response["data"]["data"]["user_wallets"];
        const total_pages = response["data"]["data"]["total_pages"];

        setWallets(newWallets);
        setTotalPages(total_pages);
      });
  };

  useEffect(() => {
    getCustomerWallets();
    // eslint-disable-next-line
  }, [isOptionSelected]);

  const handleClick = (customerID) => {
    navigator("/customer-wallet-history/" + customerID);
  };

  const exportToCSV = () => {
    if (wallets.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Customer,Balance,Total Funded,Total Spent,Total Withdrawn" + "\r\n";
    console.log(wallets);

    wallets.forEach(function (shipment) {
      let walletDetails =
        shipment.user_name +
        "," +
        shipment.account_balance +
        "," +
        shipment.total_funded +
        "," +
        shipment.total_spent +
        "," +
        shipment.total_withdrawn;

      csvContent += walletDetails + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "CustomersWallet.csv");
    document.body.appendChild(link);

    link.click();
  };

  const onChangePaymentStatus = (event) => {
    setChosenPaymentStatus(event.target.value);
  };

  const toggleCustomDateRangeDialog = () => {
    setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
  };

  const searchForCustomers = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = () => {
    const formData = new FormData(form.current);
    const query = formData.get("query");
    setQuery(query);

    if (query.toString().trim() === "") {
      setIsQuerySearching(false);
      setResultContainerVisibility(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);

    axios.post(searchForCustomersURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerQueryResults(customers);
    });
  };

  const paginate = (num) => {
    setCurrentPage(num);
    navigator(`/transactions?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    navigator(`/transactions?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    navigator(`/transactions?page=${currentPage - 1}`);
  };

  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="container-fluid shipments-container">
            <CustomDateRangeDialog
              isModalVisible={isCustomDateRangeDialogVisible}
              handleCloseDialog={(e) => {
                if (typeof e === "string") {
                  console.log("The chosen date is " + e.toString());
                  setChosenDate(e);
                }
                toggleCustomDateRangeDialog("close");
              }}
            />

            <div
              className="card border-0 p-4 min-vh-100"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={WalletIcon} alt="group" />
                  <h5 className="m-0">Customers Wallet</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <div className="d-md-flex gap-2 ">
                    <div className="d-flex">
                      <form
                        className="query-result-container "
                        ref={form}
                        onSubmit={searchForCustomers}
                      >
                        <SearchBox
                          onChange={handleQueryChange}
                          name="query"
                          value={query}
                          placeholder="Search using customer's name or number"
                        />
                        <div
                          className={`query-result ${
                            resultContainerVisibility === true
                              ? "result-visible"
                              : null
                          }`}
                        >
                          {setIsQuerySearching === true
                            ? null
                            : customerQueryResults.map((customer) => {
                                return (
                                  <div
                                    key={customer.id}
                                    className="d-flex flex-column"
                                    onClick={() => handleClick(customer.id)}
                                  >
                                    <span style={{ fontSize: 15 }}>
                                      {customer.fullname}
                                    </span>
                                    <span className="d-flex align-items-center">
                                      <span style={{ fontSize: 13 }}>
                                        {customer.phone}
                                      </span>
                                      <BsDot />
                                      <span style={{ fontSize: 13 }}>
                                        {customer.email}
                                      </span>
                                    </span>
                                  </div>
                                );
                              })}
                        </div>
                      </form>
                    </div>

                    <div>
                      <button
                        className="feature-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickExport}
                      >
                        <img src={ExportIcon} alt="export" />
                        Export
                        {open ? <BsChevronUp /> : <BsChevronDown />}
                      </button>
                      <Menu
                        id="basic-menu"
                        anchorEl={openExport}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            exportToCSV();
                            handleClose();
                          }}
                          style={{ fontSize: 12 }}
                        >
                          Export to csv
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Customer</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Total Funded</th>
                      <th scope="col">Total Spent</th>
                      <th scope="col">Total Withdrawn</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {wallets.map((transaction) => {
                        return (
                          <tr
                            key={transaction.id}
                            onClick={() => handleClick(transaction.user_id)}
                          >
                            <td>{transaction.user_name}</td>
                            <td>
                              ₦
                              {parseFloat(
                                transaction.account_balance
                              ).toLocaleString("en")}
                            </td>
                            <td>
                              ₦
                              {parseFloat(
                                transaction.total_funded
                              ).toLocaleString("en")}
                            </td>
                            <td>
                              ₦
                              {parseFloat(
                                transaction.total_spent
                              ).toLocaleString("en")}
                            </td>
                            <td>
                              ₦
                              {parseFloat(
                                transaction.total_withdrawn
                              ).toLocaleString("en")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {isRetrievingMore === false ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersWallet;
