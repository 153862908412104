import "../styles/sidebar.scss";
import * as BoxIcons from "react-icons/bi";
import { newSidebarData, SidebarData } from "./SidebarData";
import SingleMenu from "./SingleMenu";
import { SessionManagement } from "../library/SessionManagement";
import styled from "styled-components";
import {useState} from "react";
import LogoIcon from "../assets/logo-icon.png";
import { Link } from "react-router-dom";

const ProfileImage = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

const Sidebar = () => {
    const companyName = SessionManagement.getCompanyName();
    const companyOperatorID =SessionManagement.getCompanyOperatorID();
    const companyLogoURL = SessionManagement.getCompanyLogoURL();

    return (
      <div className="sidebar-wrap" >
        <div className="sidebar d-none d-xl-flex flex-column justify-content-between">
          <div>
            <Link to="/" className="logo">
              <div className="d-flex align-items-center flex-row mt-1">
                <ProfileImage
                  src={companyLogoURL}
                  className="avatar"
                  alt=""
                />
                <div className="details d-flex flex-column align-items-center">
                  <p className="operator-name">{companyName}</p>
                  {/* <span className="operator-id">Operator ID: {companyOperatorID}</span> */}
                </div>
              </div>
            </Link>

            <div className="menu">
              <ul className="menu-list">
                {newSidebarData.map((item, index) => {
                    if (companyName !== "520 Logistics" && item.title === "Partners") {
                        return null;
                    }
                  return <SingleMenu item={item} key={index} />;
                })}
              </ul>
            </div>
          </div>
          <div className="company-banner" >
            <p className="text-01" >Powered by</p>
            <div className="banner-section-2" >
              <div className="banner-logo">
                <img src={LogoIcon} alt="icon" />
              </div>
              <p className="text-02" >parcelflow</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Sidebar;
