import React, { useEffect, useState } from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
import SenderIcon from "../../../assets/create-shipment/sender.svg";
import SenderActiveIcon from "../../../assets/create-shipment/sender-active.svg";
import ReceiverIcon from "../../../assets/create-shipment/receiver.svg";
import ReceiverActiveIcon from "../../../assets/create-shipment/receiver-active.svg";
import PaymentIcon from "../../../assets/create-shipment/payment.svg";
import PaymentActiveIcon from "../../../assets/create-shipment/payment-active.svg";
import ConfirmIcon from "../../../assets/create-shipment/confirm.svg";
import ConfirmActiveIcon from "../../../assets/create-shipment/confirm-active.svg";
import Sender from "./Sender";
import Receiver from "./Receiver";
import Payment from "./Payment";
import Confirm from "./Confirm";

const LocalShipment = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [carriers, setCarriers] = useState([]);
  const [formData, setFormData] = useState({
    sender_details: {
      fullname: "",
      email: "",
      phone: "",
      address: "",
      coordinates: "",
    },
    receiver_details: {
      fullname: "",
      email: "",
      phone: "",
      address: "",
      coordinates: "",
    },
    payment_method: "",
    insurance: "No",
    vehicle_type: "Motorbike",
    transporter: "",
    description: "",
    pickup_cost: "",
    selectedCarrier: {},
    term: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const nextPage = () => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <ul className="create-shipment-form-tabs">
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 0 && "create-shipment-form-tabs-option-active"
          }`}
        >
          {currentPage === 0 ? (
            <img src={SenderActiveIcon} alt="icon" />
          ) : (
            <img src={SenderIcon} alt="icon" />
          )}
          Sender
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 0 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 1 && "create-shipment-form-tabs-option-active"
          }`}
        >
          {currentPage === 1 ? (
            <img src={ReceiverActiveIcon} alt="icon" />
          ) : (
            <img src={ReceiverIcon} alt="icon" />
          )}
          Receiver
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 1 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 2 && "create-shipment-form-tabs-option-active"
          }`}
        >
          {currentPage === 2 ? (
            <img src={PaymentActiveIcon} alt="icon" />
          ) : (
            <img src={PaymentIcon} alt="icon" />
          )}
          Pricing & Payment
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 2 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 3 && "create-shipment-form-tabs-option-active"
          }`}
        >
          {currentPage === 3 ? (
            <img src={ConfirmActiveIcon} alt="icon" />
          ) : (
            <img src={ConfirmIcon} alt="icon" />
          )}
          Confirm
        </li>
      </ul>
      <div>
        {currentPage === 0 ? (
          <Sender
            nextPage={nextPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : currentPage === 1 ? (
          <Receiver
            nextPage={nextPage}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
            setCarriers={setCarriers}
          />
        ) : currentPage === 2 ? (
          <Payment
            nextPage={nextPage}
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
            carriers={carriers}
          />
        ) : currentPage === 3 ? (
          <Confirm
            previousPage={previousPage}
            formData={formData}
            setFormData={setFormData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default LocalShipment;
