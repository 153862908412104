import React, { useState } from "react";
import SenderIcon from "../../../assets/create-shipment/sender-page.svg";
import { Col, Row } from "react-bootstrap";
import { PhoneFieldBox, TextBox } from "../../../components/InputFields";
import {
  getAddressSuggestionsURL,
  searchForCustomersURL,
} from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";

const Sender = ({ nextPage, formData, setFormData }) => {
  const [locations, setLocations] = useState([]);
  const [showAddressSuggestions, setShowAddressSuggestions] = useState(false);
  const [showSenderSuggesstions, setShowSenderSuggestions] = useState(false);
  const [customers, setCustomers] = useState([]);

  const fetchLocationSuggestions = async (keyword) => {
    const data = {
      keyword: keyword,
    };

    const newData = { ...data, ...UserProfileData() };
    await axios
      .post(getAddressSuggestionsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const predictions = response["data"]["predictions"];

        setShowAddressSuggestions(true);
        setLocations(predictions);
      });
  };

  const searchForCustomers = async (query) => {
    if (query.toString().trim() === "") {
      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };
    await axios
      .post(searchForCustomersURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const customers = response["data"]["data"];

        setShowSenderSuggestions(true);
        setCustomers(customers);
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });
  };

  const handleAddress = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });

    fetchLocationSuggestions(value);
  };

  const handleSender = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        [name]: value,
      },
    });

    searchForCustomers(value);
  };

  const validatePage = () => {
    if (formData.sender_details.fullname === "") {
      return true;
    } else if (formData.sender_details.address === "") {
      return true;
    }

    return;
  };

  const selectPlace = (location) => {

    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        address: location,
      },
    });

    setShowAddressSuggestions(false);
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        phone: code,
      },
    });
  };

  const selectSender = (customerName, customerNumber, emailAddress) => {
    setFormData({
      ...formData,
      sender_details: {
        ...formData.sender_details,
        fullname: customerName,
        email: emailAddress,
        phone: customerNumber,
      },
    });

    setShowSenderSuggestions(false);
  };

  return (
    <div className="create-shipment-grid">
      <div className="create-shipment-info">
        <img src={SenderIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Sender Details</p>
            <p className="create-shipment-info-sub">
              Who is sending this package and from where?
            </p>
          </div>
          <div>
            <p>Saved Customers</p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <Row className="create-shipment-info-form-row">
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="fullname">FullName</label>
            <TextBox
              name="fullname"
              value={formData.sender_details.fullname}
              onChange={handleSender}
              placeholder="Sender Full Name"
            />
            <div
              style={{ width: "50%" }}
              className={`suggestions-field ${
                showSenderSuggesstions ? "suggestions-field-visible" : null
              }`}
            >
              {customers.map((suggestions) => {
                const fullName = suggestions.fullname;
                const phoneNumber = suggestions.phone;
                const emailAddress = suggestions.email;
                return (
                  <div
                    onClick={(e) =>
                      selectSender(fullName, phoneNumber, emailAddress)
                    }
                    key={suggestions.id}
                    className="single-suggestion"
                  >
                    <span>{fullName}</span>
                    <div className="d-flex flex-row align-items-center">
                      <span
                        style={{
                          fontSize: 13,
                          marginTop: -5,
                        }}
                      >
                        {phoneNumber}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Email Address (optional)</label>
            <TextBox
              name="email"
              type="email"
              value={formData.sender_details.email}
              onChange={handleInput}
              placeholder="Sender Email"
            />
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Phone Number</label>
            {/* <TextBox
              name="phone"
              type='tel'
              value={formData.sender_details.phone}
              onChange={handleInput}
              placeholder="Sender's phone no"
            /> */}
            <PhoneFieldBox
              value={formData.sender_details.phone}
              onChange={handlePhoneInput}
            />
          </Col>
          <Col sm={12}>
            <label htmlFor="email">Address [Pickup]</label>
            <TextBox
              name="address"
              value={formData.sender_details.address}
              onChange={handleAddress}
              placeholder="Input your address [pickup] here"
            />
            <div
              style={{ width: "50%" }}
              className={`suggestions-field ${
                showAddressSuggestions ? "suggestions-field-visible" : null
              }`}
            >
              {locations?.map((suggestions) => {
                const mainText = suggestions.structured_formatting.main_text;
                const description =
                  suggestions.structured_formatting.secondary_text;
                return (
                  <div
                    onClick={(e) => selectPlace(mainText)}
                    key={suggestions.place_id}
                    className="single-suggestion"
                  >
                    <span>{mainText}</span>
                    <span style={{ fontSize: 13, marginTop: -5 }}>
                      {description}
                    </span>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="create-shipment-info-form-btn"
            onClick={nextPage}
            disabled={validatePage()}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sender;
